<template>
  <div>
    <v-app-bar id="home-app-bar" app elevation="2" inverted-scroll scroll-threshold="500" color="white">
      <base-img :src="require('@/assets/incognit/Incognit_Cloud.png')" class="mr-3 hidden-xs-only" contain max-width="116"
        width="100%" />

      <v-spacer />

      <div>
        <v-tabs v-model="active_tab" class="hidden-sm-and-down" background-color="transparent">
          <v-tab v-for="(item, i) in items" :key="i" class="font-weight-bold custom-tabs" min-width="96" text
            @click="toTop(item.id)">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
    </v-app-bar>
  </div>
</template>

<script>
import { EventBus } from '../../event-bus'
// :exact="item === 'Home'"
// :ripple="false"
// :to="item.id"
/*   {name: 'Who We Are',
        id: '#theme-features',
        index: 1,
        },
        {name: 'The Team',
        id: '#affiliates',
        index: 2,
        },
        {name: 'Work With Us',
        id: '#social',
        index: 3,
        },
        {name: 'Contact',
        id: '#pro-features',
        index: 4,
        },
*/

export default {
  name: 'HomeAppBar',

  data: () => ({
    active_tab: null,
    drawer: null,
    items: [
      {
        name: 'Home',
        id: '#home',
        index: 0,
      },
      // {
      //   name: 'Who We Are',
      //   id: '#theme-features',
      //   index: 1,
      // },
      // {
      //   name: 'The Team',
      //   id: '#affiliates',
      //   index: 2,
      // },
      // {
      //   name: 'Work With Us',
      //   id: '#social',
      //   index: 3,
      // },
      // {
      //   name: 'Contact',
      //   id: '#pro-features',
      //   index: 4,
      // },

    ],
  }),
  created() {
    EventBus.$on('changeTab', this.changeTab)
  },
  methods: {
    changeTab(e) {
    },
    toTop(i) {
      this.$vuetify.goTo(i)
    },
  },
}

</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
